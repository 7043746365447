@import "tailwind";
@import "utilities";

@mixin hidden() {
    display: none;
}

*, ::before, ::after {
    box-sizing: border-box;
}

html {
    //font-size: 14px;
    //font-family: Inter, sans-serif;
    //line-height: 1.5;
    //-webkit-text-size-adjust: 100%;
    display: block;

    body {
        margin: 0;
    }
}
