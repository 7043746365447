
#page-breadcrumbs {
    @media screen and (max-width: 800px) {
        display: none;
    }
    
    ul {
        display: flex;
        margin-inline-start: 1rem;
        gap: 0.5rem;
        height: 100%;

        li {
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;

            .breadcrumb-text {
                margin-inline: 1rem;
            }

            .arrow {
                position: absolute;
                border-right: 1px solid currentColor;
                height: 50%;
                width: 0;

                &.arrow-top {
                    top: 0;
                    transform: rotate(-10deg);
                }

                &.arrow-bottom {
                    bottom: 0;
                    transform: rotate(10deg);
                }
            }
        }
    }
}
