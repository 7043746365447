
#commander {
    position: fixed;
    inset: 0 0 0 0;
    pointer-events: none;
    z-index: 2;

    #commander-backdrop {
        position: fixed;
        inset: 0 0 0 0;
        background-color: transparentize(black, 0.3);
        z-index: 10;
        width: 100vw;
        height: 100vh;
    }

    #commander-modal {
        border-radius: 0.75rem;
        flex-direction: column;
        margin: 10rem auto auto;
        width: 50vw;
        max-width: 50rem;
        position: relative;
        min-height: 8rem;
        max-height: 40vh;
        display: flex;
        flex-flow: column nowrap;
        gap: 0.5rem;
        padding: 1.2rem;
        z-index: 11;

        &, * {
            pointer-events: all;
            font-size: 1.2rem;
        }

        label {
            display: block;
            color: #eee;
            font-size: 1.5rem;
        }

        input {
            padding: 0.5rem 1rem;
            color: black;
            font-size: 1.3rem;
        }

        #list {
            list-style: none;
            margin: 0;
            border-radius: 0 0 5px 5px;
            border: 1px #ccc solid;
            overflow-y: scroll;

            li {
                display: block;
                padding: 5px 15px;

                &:hover, &.highlighted {
                    background-color: #fff;
                }

                .command-data {
                    display: flex;
                    justify-content: space-between;
                }

                span {
                    font-weight: 550;
                }

                p {
                    margin: 5px 0 0;
                }
            }
        }
    }
}
