
#dashboard-instance-selector {
    width: 60vw;
    height: 100%;
    margin-inline: auto;
    margin-block-end: -4rem;

    @media screen and (min-width: 901px) and (max-width: 1200px) {
        width: 75vw;
    }

    @media screen and (max-width: 900px) {
        width: 90vw;
    }

    #dashboard-instance-selector-title {
        width: 100%;
        color: #a0aec0;
        font-size: 1.875rem;
        font-weight: 300;
        justify-content: center;
        align-items: center;
        display: flex;
        height: unset;
        margin-top: 1rem;
        margin-bottom: 2rem;

        @media screen and (max-width: 900px) {
            height: unset;
            margin-top: 2rem;
            margin-bottom: 4rem;
        }
    }

    input {
        border-radius: 0.375rem;
        margin-bottom: 1rem;
    }

    #instance-list {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 0.75rem;
        cursor: pointer;
        max-height: calc(100% - 20vh);
        overflow-y: auto;

        @media screen and (min-width: 901px) and (max-width: 1200px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (min-width: 701px) and (max-width: 900px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: 700px) {
            grid-template-columns: repeat(1, 1fr);
        }

        li {
            padding: 1rem;
            border: 1px solid transparent;
            border-radius: 0.375rem;
            background-color: #fff;
            transition: all 0.3s ease-in-out;

            &.instance-has-incident {
                border: 2px solid red;
            }

            &.instance-highlighted {
                border-color: #2D3748;

                &:before {
                    content: '>';
                    margin-right: 0.5rem;
                }
            }
        }
    }
}
