
#user-menu {
    min-width: 4.5rem;
}

@media screen and (max-width: 900px) {
    #user-menu-button div {
        display: none;
    }

    #user-menu-dropdown {
        right: 0;
        left: unset;

        a {
            padding-right: 1.5rem;
        }
    }
}
