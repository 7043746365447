.h-screen {
    height: 100vh;
}

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.hidden {
    display: none;
}

.relative {
    position: relative;
}

.uppercase {
    text-transform: uppercase;
}

.mt-auto {
    margin-top: auto;
}

.mb-auto {
    margin-bottom: auto;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.pt-auto {
    padding-top: auto;
}

.pb-auto {
    padding-bottom: auto;
}

.pl-auto {
    padding-left: auto;
}

.pr-auto {
    padding-right: auto;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.m-0 {
    margin: 0;
}

@for $i from 1 through 100 {
    .m-#{$i} {
        margin: calc(#{$i}rem / 4);
    }
    .my-#{$i} {
        margin-block: calc(#{$i}rem / 4);
    }
    .mx-#{$i} {
        margin-inline: calc(#{$i}rem / 4);
    }
    .mt-#{$i} {
        margin-top: calc(#{$i}rem / 4);
    }
    .ml-#{$i} {
        margin-left: calc(#{$i}rem / 4);
    }
    .mr-#{$i} {
        margin-right: calc(#{$i}rem / 4);
    }
    .mb-#{$i} {
        margin-bottom: calc(#{$i}rem / 4);
    }

    .p-#{$i} {
        padding: calc(#{$i}rem / 4);
    }
    .py-#{$i} {
        padding-block: calc(#{$i}rem / 4);
    }
    .px-#{$i} {
        padding-inline: calc(#{$i}rem / 4);
    }
    .pt-#{$i} {
        padding-top: calc(#{$i}rem / 4);
    }
    .pl-#{$i} {
        padding-left: calc(#{$i}rem / 4);
    }
    .pr-#{$i} {
        padding-right: calc(#{$i}rem / 4);
    }
    .pb-#{$i} {
        padding-bottom: calc(#{$i}rem / 4);
    }
}
