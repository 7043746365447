
.instance-quick-selection {
    .action-row {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 0.5rem;
        max-width: 50rem;

        .action {
            padding: 0.5rem 1rem;
            border: 1px solid #2D3748;
            border-radius: 0.375rem;
            background-color: #2d3748;
            color: white;
            transition: all 0.3s ease-in-out;
            display: flex;
            align-items: center;
            font-size: 1rem;

            &:hover {
                color: rgba(45, 55, 72, 1);
                background-color: transparent;

                svg {
                    fill: unset;
                }
            }

            svg {
                fill: white;
                height: 1.2rem;
                margin-right: 0.5rem;
            }
        }
    }
}
