
.conneqt-logo {
    animation: scaleIn;
    animation-duration: 1s;
    animation-delay: .2;

    .shimmer {
        transform: rotate(45deg) translateX(100%) translateY(-100%);
        animation: shimmer;
        animation-duration: .4s;
        animation-timing-function: ease-in-out;
        animation-delay: .3s;
    }
}

@keyframes scaleIn {
    0% {
        transform: scale(.75, .75);
    }
    10% {
        transform: scale(1.25, 1.25);
    }
    20% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(1, 1);
    }
}

@keyframes shimmer {
    0% {
        transform: rotate(45deg) translateX(0%) translateY(-50%);
    }
    100% {
        transform: rotate(45deg) translateX(100%) translateY(-100%);
    }
}
