@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,500,600,700&display=swap');

.primary-button {
    @apply py-2 px-4 bg-gray-800 text-white text-sm rounded border-solid border border-gray-800;
}

.primary-button:hover {
    @apply text-gray-800 bg-transparent;
}

.secondary-button {
    @apply py-2 px-4 bg-gray-400 text-gray-800 text-sm rounded border-solid border border-gray-400;
}

.secondary-button:hover {
    @apply text-gray-800 bg-transparent;
}

.destructive-button {
    @apply py-2 px-4 bg-red-600 text-white text-sm rounded border-solid border border-red-600;
}

.destructive-button:hover {
    @apply text-gray-800 bg-transparent;
}

.generic-checkbox {
    @apply appearance-none w-5 h-5 border border-gray-300 rounded outline-none;
}

.generic-checkbox:checked {
    @apply bg-gray-700 border-gray-700;
    background-image: url("data:image/svg+xml,%0A%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23fff' d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'%3E%3C/path%3E%3C/svg%3E");
    background-size: 15px 15px;
    background-position: center;
    background-repeat: no-repeat;
}

/** JSON Editor **/
.jsoneditor-modal .pico-modal-header {
    @apply bg-gray-800;
}

.jsoneditor-modal .jsoneditor-button-group.jsoneditor-button-group-value-asc input.jsoneditor-button-asc,
.jsoneditor-modal .jsoneditor-button-group.jsoneditor-button-group-value-desc input.jsoneditor-button-desc {
    @apply bg-gray-800;
}

.jsoneditor {
    border: 0 !important;
    @apply border-l-2 border-gray-500 rounded-lg !important;
}

.jsoneditor .jsoneditor-menu {
    @apply bg-gray-800 border-0 text-white;
}

.jsoneditor .jsoneditor-menu ul {
    @apply shadow-none bg-gray-600;
}

.jsoneditor .jsoneditor-menu button {
    @apply text-white;
}

.jsoneditor .jsoneditor-menu .jsoneditor-frame {
    @apply rounded-tr;
}

.jsoneditor .jsoneditor-tree {
    @apply overflow-auto;
}

.jsoneditor .jsoneditor-tree .jsoneditor-tree-inner {
    @apply my-2;
}

.jsoneditor .jsoneditor-poweredBy {
    @apply hidden;
}

@tailwind utilities;

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.transition-ease-in-ease-out {
    transition-property: all;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
}

ul {
    list-style-type: none;
    padding: 0;
}
