
.instance-data-backdrop {
    &:before {
        content: '';
        position: fixed;
        inset: 0;
        width: 100vmax;
        height: 100vmax;
        background-color: rgba(26, 32, 44, 0.2);
        z-index: 1000;
    }
}

.instance-data-popup {
    top: 50%;
    position: fixed;
    width: 40rem;
    max-width: 90vw;
    height: max-content;
    max-height: 80vh;
    z-index: 99999;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1.2rem;
    border-radius: 0.3rem;
    border: 1px solid #d2d6dc;
    background-color: #fff;
    overflow-y: auto;

    &__header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        .close-popup {
            font-size: 1.5rem;
            cursor: pointer;
        }
    }

    dl {
        display: grid;
        grid-template-columns: 10rem 1fr;
        grid-gap: 0.5rem;
        margin-top: 1rem;

        dt {
            font-weight: 500;
        }

        dd {
            position: relative;

            &.can-copy {
                cursor: pointer;
            }

            .v-enter-active,
            .v-leave-active {
                transition: opacity 0.5s ease;
            }

            .v-enter-from,
            .v-leave-to {
                opacity: 0;
            }

            .copy-tooltip {
                position: absolute;
                left: 50%;
                top: -1.7rem;
                transform: translateX(-50%);
                background-color: #2D3748;
                color: #ffffff;
                width: max-content;
                padding: 0.2rem 0.5rem;
                border-radius: 0.5rem;
            }
        }
    }
}
