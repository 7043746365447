
@import "styles/build";

main {
    padding: 3rem 3rem 6rem;
}

@media screen and (max-width: 1200px) {
    main {
        padding: 2rem 1rem 6rem;
    }
}
